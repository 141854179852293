<script lang="ts" setup>
import type { HomeBanner } from "@/types";

defineProps<{ banner: HomeBanner }>();

const { t } = useT();
const { questData } = useHomePage();
const { handleJoinQuest } = useGameQuest();
const { totalTasksPrize } = useGetPageQuestData({ immediate: false });
const { data: appInit } = useAppInitData();
const router = useRouter();
const { open } = useTaoModals();

const loggedIn = computed(() => !appInit.value?.isGuest);

const questInfo = computed(() => {
	if (!questData?.value?.quest && questData.value?.futureQuest) {
		return {
			grandPrize: questData.value?.futureQuest?.grandPrize
		};
	} else {
		return questData?.value?.quest?.questInfo;
	}
});

const handleClick = async () => {
	if (!loggedIn.value) {
		open("LazyOModalSignup");
		return;
	}
	if (!questData?.value?.quest?.isSubscribed && questData?.value?.quest?.questInfo?.activeQuestId) {
		await handleJoinQuest(questData?.value?.quest?.questInfo?.activeQuestId, true);
	}
	router.push("/quest/");
};

const handleClickHowItWorksPopup = () => {
	open("LazyOModalQuestHowItWorks");
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleClick"
	>
		<template #default>
			<AText :size="{ full: 28, md: 20 }" :modifiers="['uppercase', 'bold']" as="div">
				<span v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div v-if="questInfo" class="app-banner__desc">
				<div class="banner-prize">
					<AText
						v-if="totalTasksPrize.totalEntries"
						:size="{ full: 36, lg: 28 }"
						:modifiers="['bold', 'uppercase']"
						class="prize-fund"
					>
						<ASvg name="12/secret-coins" class="icon" />
						{{ numberFormat(totalTasksPrize.totalEntries) }}
					</AText>
					<AText
						v-if="totalTasksPrize.totalCoins"
						:size="{ full: 36, lg: 28 }"
						:modifiers="['bold', 'uppercase']"
						class="prize-fund"
					>
						<ASvg name="12/coins" class="icon" />
						{{ numberFormat(totalTasksPrize.totalCoins) }}
					</AText>
				</div>
			</div>
		</template>

		<template #actions>
			<AButton variant="primary" size="md" class="banner__btn">
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ banner.buttonText }}</AText>
			</AButton>
			<AText
				class="banner__btn-link color-neutral"
				:size="{ full: 16, md: 14 }"
				:modifiers="['link', 'underline']"
				@click="handleClickHowItWorksPopup"
			>
				{{ t("How it works") }}
			</AText>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.banner__btn {
	width: 240px;
}

.banner-prize {
	display: flex;
	align-items: center;
	gap: 16px;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		gap: 5px;
		margin: 5px 0;
	}
}

.prize-fund {
	display: inline-flex;
	align-items: center;
	gap: 8px;

	--m-fund-entries-color: var(--neutral);
	--m-fund-coins-color: var(--neutral);

	.icon {
		font-size: 40px;
	}
}

:deep(.app-banner__title) {
	max-width: 520px;
}

:deep(.app-banner__actions) {
	flex-direction: row;
	gap: 40px;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		gap: 10px;
	}
}
</style>
